import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: AngularFireAuth) { }

  login(username, password) {
    let email = username + "@fst.it";

    return new Promise<any>((resolve, reject) => {
      this.auth.signInWithEmailAndPassword(email, password).then(
        res => resolve(res),
        err => reject(err)
      )
    });
  }

  logout(){
    return new Promise((resolve, reject) => {
      this.auth.signOut().then(
        res => resolve(res),
        err => reject(err)
      );
    });
  }

  changePassword(password){
    return new Promise<any>((resolve, reject) => {
      let user = this.auth.currentUser.then(user =>{
        user.updatePassword(password).then(res => resolve(res), err => reject(err));
      });
    });
  }

  /*
  registerUser(value){
   return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(
       res => resolve(res),
       err => reject(err))
   })
  }

  logoutUser(){
   return new Promise((resolve, reject) => {
      if(firebase.auth().currentUser){
        firebase.auth().signOut()
        .then(() => {
          resolve();
        }).catch((error) => {
          reject();
        });
      }
    })
  }
 
  userDetails(){
    return firebase.auth().currentUser;
  }*/
}